<template>
  <div class="wrap">
    <div class="bg"></div>
    <div class="head">
      <div class="login">
        <img class="img-logo" :src="config.image1" alt="logo" />
      </div>
      <div class="title">{{ $t("common.app") }}</div>
	  <div v-if="slide">{{$t('report.slide')}}:{{slide === '0' ? $t('report.left') : $t('report.right')}}</div>
	  <div v-else></div>
    </div>
	<div class="el-dropdown">
	  <!-- <el-dropdown  @command="handleLang">
	    <span class="el-dropdown-link">
	      {{lang == 'zh-cn' ? '中文' : 'English'}}<i class="el-icon-arrow-down el-icon--right"></i>
	    </span>
	    <el-dropdown-menu slot="dropdown">
	      <el-dropdown-item command="zh-cn">中文</el-dropdown-item>
	      <el-dropdown-item command="en">English</el-dropdown-item>
	    </el-dropdown-menu>
	  </el-dropdown> -->
    <span @click="handleLang('zh-cn')">CN</span>
    <el-divider direction="vertical"></el-divider>
    <span @click="handleLang('en')">EN</span>
	</div>
    <div class="main">
      <div class="logo">
        <img class="img-logo" :src="config.image" alt="logo" />
      </div>
      <form class="form">
        <div class="row">
          <img
            class="icon"
            src="../../assets/img/yonghuming.png"
            alt="icon-username"
          />
          <input
            type="text"
            v-model="account"
            :placeholder="$t('account.mobile')"
          />
        </div>
        <div class="row">
          <img
            class="icon"
            src="../../assets/img/ziyuanxhdpi.png"
            alt="icon-pwd"
          />
          <input
            type="password"
            v-model="password"
            :placeholder="$t('account.password')"
          />
        </div>
        <div class="row-code">
          <div class="row code">
            <img
              class="icon"
              src="../../assets/img/email.png"
              alt="icon-code"
            />
            <input
              type="text"
              v-model="captcha"
              @keyup.enter="login"
              :placeholder="$t('account.code')"
            />
          </div>
          <div class="img-code" @click="random = Math.random()">
            <img
              style="width: 100%; height: 100%"
              :src="$baseUrl + '/dabo/captcha?' + random"
              alt="captcha"
            />
          </div>
        </div>
        <div class="flex">
          <div>
            <el-checkbox v-model="checked">
              <span>{{ $t("account.remember") }}</span>
            </el-checkbox>
          </div>
          <div class="forget">
            <el-tooltip
              class="item"
              effect="dark"
              :content="config.service"
              placement="top"
            >
              <span>{{ $t("account.forget") }}?</span>
            </el-tooltip>
          </div>
        </div>
        <input
          type="button"
          class="btn-login"
          :value="$t('account.login')"
          @click="login"
        />
        <div class="link-reg">
          <router-link to="/register">{{ $t("account.register") }}</router-link>
        </div>
      </form>
    </div>

    <div class="foot" v-if="lang !== 'en'">
      <a target="_blank" href="https://beian.miit.gov.cn"
        >闽ICP备12021955号-4</a
      >
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=35020502000503"
        ><img
          style="margin: 0 10px"
          src="../../assets/img/ba.png"
          alt=""
        />闽公网安备 35020502000503号</a
      >
      <span style="margin: 0 10px">{{ $t("common.app") }} {{config.version}}</span>
    </div>
  </div>
</template>

<script> 
export default {
	props: {
	    slide: { 
	      type: String,
	      default: ''
		},
	},
  data() {
    return {
	    lang: this.$i18n.locale,
      account: "", 
      password: "",
      random: "", //验证码随机参数
      captcha: "", //验证码,
      config: "",
      checked: false, //是否记住密码
    };
  },
  beforeMount() {
    let cookie = this.$cookieStore.getCookie("doubleUserInfo");
    if (cookie) {
      let info = JSON.parse(cookie);
      this.account = info.account;
      this.password = info.password;
      this.checked = true;
    }
  },
  mounted() {
    this.getConfig();
    this.handleOrigin()
  },
  methods: {
    login() {
      this.$api.login({
        account: this.account,
        password: this.password,
        captcha: this.captcha
      }).then((res) => {
        if (res.code) {
          //登录成功
          if (this.checked) {
            //记住密码
            this.$cookieStore.setCookie("doubleUserInfo", JSON.stringify({account: this.account, password: this.password}));
          } else {
            //删除
            this.$cookieStore.delCookie("doubleUserInfo");
          }
          localStorage.setItem("token", res.data.token);
          this.$router.push("home");
          return false;
        }
        this.random = Math.random();
        this.$message.error(res.msg);
      });
    },
  	//切换语言
  	handleLang(lang){
  	  this.lang = lang;
  	  this.$cookieStore.setCookie('DB_LANG', lang);
  	  this.$i18n.locale = lang;
  	},
    getConfig() {
      this.$api.config().then((res) => {
        this.config = res.data;
      });
    },
    handleOrigin(){
      if (location.origin.indexOf('en.') >= 0) {
        this.handleLang('en')
      }else{
        this.handleLang('zh-cn')
      }
    }
  },
};
</script>

<style scoped="scoped">
input:focus {
  outline: none;
  border: none;
}
.wrap {
  /* position: relative; */
}
.bg {
  background-image: url(../../assets/img/bg1.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #fff;
  background-position: center 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 1000px;
  z-index: -10;
  zoom: 1;
}
.head {
  position: absolute;
  left: 6.5%;
  top: 6.5%;
  display: flex;
  align-items: center;
}
.head .img-logo {
  width: 202px;
  margin-right: 53px;
}
.head .title {
  font-size: 36px;
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  color: #ffffff;
}
.main {
  width: 424px;
  height: 500px;
  background: #ffffff;
  border-radius: 11px;
  padding: 24px 77px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
}
.main .logo {
  text-align: center;
}
.main .img-logo {
  width: 135px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.main .form .row {
  margin-bottom: 25px;
  padding-left: 83px;
  border: 1px solid #eae8eb;
  border-radius: 8px;
  position: relative;
}
.main .form .flex {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  color: #d4d3d9;
}

.main .form .code {
  width: 209px;
  margin-bottom: 0;
}

.main .form .icon {
  position: absolute;
  left: 32px;
  top: 50%;
  width: 24px;
  height: 24px;
  transform: translate(0%, -50%);
}
.main .form .code .icon {
  width: 23px;
  height: 19px;
}
.main .form input {
  width: 100%;
  height: 54px;
  background: #ffffff;
  border: none;
  font-size: 15px;
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
}
.main .form .row-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}
.main .form .row-code .img-code {
  width: 117px;
  height: 54px;
  background: royalblue;
  border: 1px solid #eae8eb;
  border-radius: 8px;
}
.main .form .forget {
  color: #1f2a66;
}
.main .form .btn-login {
  width: 100%;
  height: 54px;
  background: #1f2a66;
  border-radius: 8px;
  font-size: 21px;
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 25px;
}
.main .form .link-reg {
  font-size: 15px;
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  color: #1f2a66;
  text-align: center;
}
.foot {
  font-size: 16px;
  font-family: FZZhunYuan-M02S;
  font-weight: 400;
  color: #364284;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
}
.el-dropdown-link {
  font-size: 20px;
  cursor: pointer;
  color: #ffffff;
  padding-right:10px
}
.el-icon-arrow-down {
  font-size: 14px;
}
.el-dropdown{
	position: absolute;
	right: 10%;
	top: 8.5%;
	width: 100px;
  cursor: pointer;
  color: #ffffff;
  font-size: 18px;
}
</style>
